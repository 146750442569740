import axios, { AxiosRequestConfig } from "axios"

const apiKey = "myAvDblHRqz80kPBN7gcRvWb1jqYySlyn7IezheO"

const BASE_URL = 'https://api.banjarmasinkota.go.id/api'
const configs: AxiosRequestConfig = {
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${apiKey}`
    },
    
}

export const getManyProvince = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/provinsi`, configs)
        const result = response?.data?.data

        return {
            result,
            error: undefined,
        }

    } catch (error) {
        return {
            error,
            result: undefined
        }
    }
}

export const getManyCity = async () => {
    const provinceCode = 63 // Kalimantan Selatan

    try {
        const response = await axios.get(`${BASE_URL}/kotaKabupaten/${provinceCode}`, configs)
        const result = response?.data?.data

        return {
            result,
            error: undefined,
        }

    } catch (error) {
        return {
            error,
            result: undefined
        }
    }
}

export const getManyDistrict = async (cityName: string) => {
    const { result: cities } = await getManyCity()
    const city = cities.find((c: any) => (c.nama_kota_kabupaten as string).includes(cityName))
    const cityCode = city.kode_kota_kabupaten
    

    try {
        const response = await axios.get(`${BASE_URL}/kecamatan/${cityCode}`, configs)
        const result = response?.data?.data

        return {
            result,
            error: undefined,
        }

    } catch (error) {
        return {
            error,
            result: undefined
        }
    }
}

export const getManyVillage = async (cityName: string, districtName: string) => {
    const { result: districts } = await getManyDistrict(cityName)
    
    const district = districts.find((d: any) => (d.nama_kecamatan as string).includes(districtName))
    const districtCode = district.kode_kecamatan

    try {
        const response = await axios.get(`${BASE_URL}/kelurahanDesa/${districtCode}`, configs)
        const result = response?.data?.data        

        return {
            result,
            error: undefined,
        }

    } catch (error) {
        return {
            error,
            result: undefined
        }
    }
}