


























































































































































































































import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KLabel from '@/@core/components/input/KLabel.vue';
import KTextAreaInput from '@/@core/components/input/KTextAreaInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref
} from '@vue/composition-api';
import { IndustryCommonForm } from '../types';
import { useClassificationStore } from '@/modules/classification/services/store';
import KCheckboxInput from '@/@core/components/input/KCheckboxInput.vue';
import KPatternInput from '@/@core/components/input/k-pattern-input.vue';
import {
  toLatitude,
  toLongitude,
  toNpwp
} from '@/@core/helpers/formatter';
import KTagInput from '@/@core/components/input/k-tag-input.vue';
import { useDistrictStore } from '@/modules/district/services/store';
import { useAuthStore } from '@/modules/auth/services/store';
import useUserModel from '@/modules/user/pages/default-page/model';
import useTableOptions from '@/@core/helpers/table-options';
import { MaybeRef } from '@vueuse/core';
import KMapInput from '../../../@core/components/input/k-map-input.vue';
import { useFinanceStore } from '@/modules/finance/services/store';
import {
  getManyDistrict,
  getManyVillage
} from '@/@core/services/api-banjarmasinkota';
import { useCommodityStore } from '@/modules/commodity/services/store';

export default defineComponent({
  components: {
    KCard,
    KText,
    KTextInput,
    KTextAreaInput,
    KLabel,
    KComboboxInput,
    KCheckboxInput,
    KPatternInput,
    KTagInput,
    KMapInput
  },
  name: 'IndustryCommonForm',
  props: {
    data: {
      type: Object as PropType<IndustryCommonForm>,
      required: true
    }
  },
  setup(props) {
    const districts = ref([]);
    const villages = ref([]);
    const authStore = useAuthStore();
    const commodityStore = useCommodityStore();
    const financeStore = useFinanceStore();
    const districtStore = useDistrictStore();
    const classificationStore = useClassificationStore();
    const businessEntityList = [
      {
        text: 'PT',
        value: 'PT'
      },
      {
        text: 'CV',
        value: 'CV'
      },
      {
        text: 'Koperasi',
        value: 'Koperasi'
      },
      {
        text: 'Yayasan',
        value: 'Yayasan'
      },
      {
        text: 'PO',
        value: 'PO'
      }
    ];
    const villageKey = ref(0);
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const districtAndVillageLoading = ref(false);
    const isEdit = computed(() => (<any>props?.data)?.id);

    const getManyClassification = async (
      search?: MaybeRef<string>
    ) => {
      isLoading.value = true;

      try {
        await classificationStore.getManyClassification(
          useTableOptions(),
          search
        );
      } finally {
        isLoading.value = false;
      }
    };

    const initClassification = async () => {
      await getManyClassification();

      // @ts-ignore
      const hasClassification = props.data.classification?.id;
      // @ts-ignore
      if (hasClassification)
        props.data.classification =
          props.data.classification.fulltext;
    };

    const getManyFinance = async (search?: MaybeRef<string>) => {
      isLoading.value = true;

      try {
        await financeStore.getManyFinance();
      } finally {
        isLoading.value = false;
      }
    };

    const initFinance = async () => {
      await getManyFinance();

      // @ts-ignore
      const hasFinance = props.data.finance?.id;
      // @ts-ignore
      if (hasFinance) props.data.finance = props.data.finance.name;
    };

    const initCommodity = async () => {
      await commodityStore.getManyCommodity();

      const hasCommodities = props.data.commodities?.length;
      // @ts-ignore
      if (hasCommodities)
        props.data.commodities = props.data.commodities.map(
          (c: any) => c.id
        );
    };

    const commodityList = [
      {
        text: 'Industri Pangan',
        value: 'Industri Pangan'
      },
      {
        text: 'Industri Kerajinan',
        value: 'Industri Kerajinan'
      },
      {
        text: 'Industri Logam dan Elektronika',
        value: 'Industri Logam dan Elektronika'
      },
      {
        text: 'Industri Sandang',
        value: 'Industri Sandang'
      },
      {
        text: 'Industri Kimia dan Bahan Bangunan',
        value: 'Industri Kimia dan Bahan Bangunan'
      },
      {
        text: 'Industri Alat Transportasi',
        value: 'Industri Alat Transportasi'
      }
    ];

    const isEmployee = computed(() => authStore.role == 'EMPLOYEE');
    const isEmployeeAndNewIndustry = computed(
      () => isEmployee.value && !isEdit.value
    );

    const initDistricts = async () => {
      districtAndVillageLoading.value = true;

      const { result, error } = await getManyDistrict(
        'Kota Banjarmasin'
      );
      if (result) districts.value = result;

      districtAndVillageLoading.value = false;
    };

    const initVillages = async () => {
      districtAndVillageLoading.value = true;

      const { result, error } = await getManyVillage(
        'Kota Banjarmasin',
        props.data?.district
      );
      if (result) villages.value = result;

      districtAndVillageLoading.value = false;
    };

    const onDistrictChange = async () => {
      // @ts-ignore
      const hasVillage = !!props.data.village;
      if (hasVillage) props.data.village = '';
      villageKey.value++;
      await initVillages();
    };

    onMounted(async () => {
      isLoading.value = true;

      await initDistricts();
      await initClassification();
      await initCommodity();
      await initFinance();

      // Create new industry by Employee, so init district by Employee district
      if (isEmployeeAndNewIndustry.value) {
        const district = useUserModel().useFindDistrict(
          authStore.user
        );
        if (district) {
          (<any>props.data).district = (<any>district).name;

          await initVillages();
        }
      }

      const hasSurveyedAt = !!props.data?.surveyedAt;

      if (hasSurveyedAt)
        props.data.surveyedAt = new Date(props.data.surveyedAt)
          .toISOString()
          .slice(0, 10);

      reRenderCount.value++;
      isLoading.value = false;
    });

    return {
      classificationStore,
      businessEntityList,
      reRenderCount,
      isLoading,
      commodityList,
      toLatitude,
      toLongitude,
      toNpwp,
      districtStore,
      isEmployeeAndNewIndustry,
      getManyClassification,
      financeStore,
      onDistrictChange,
      districts,
      villages,
      villageKey,
      districtAndVillageLoading,
      commodityStore
    };
  }
});
