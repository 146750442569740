






























































































import KButtonAction from '@/@core/components/button/KButtonAction.vue';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import KFileInput from '@/@core/components/input/KFileInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import { loadPermissionFile } from '@/@core/helpers/loader';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import {
  defineComponent,
  PropType,
  Ref,
  ref
} from '@vue/composition-api';
import { useIndustryStore } from '../services/store';
import { IndustryPermissionForm, Permission } from '../types';
import KImageViewer from '@/@core/components/other/k-image-viewer.vue';

export default defineComponent({
  components: {
    KCard,
    KText,
    KButtonAction,
    KDialog,
    KTextInput,
    KFileInput,
    KImageViewer
  },
  name: 'IndustryPermissionForm',
  props: {
    data: {
      type: Object as PropType<IndustryPermissionForm>,
      required: true
    },
    id: {
      type: String
    }
  },
  emits: ['refresh:data'],
  setup(props, { emit }) {
    const store = useIndustryStore();
    const headers = [
      {
        value: 'value',
        text: 'No Izin'
      },
      {
        value: 'type',
        text: 'Jenis Izin'
      },
      {
        value: 'agency',
        text: 'Instansi'
      },
      {
        value: 'date',
        text: 'Tanggal'
      },
      {
        value: 'filePath',
        text: 'File'
      },
      {
        value: 'actions',
        text: '',
        width: '1%',
        sortable: false
      }
    ];
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const showFormDialog = ref(false);
    const showDeleteDialog = ref(false);
    const formData: Ref<Permission> = ref({
      value: '',
      type: '',
      agency: '',
      date: undefined
    });

    const onSubmit = async () => {
      const data: any = { ...formData.value };
      const industryId = (props as any).data?.id;
      data.industry = industryId;

      isLoading.value = true;

      const result = await store.postPermission(data);
      if (result) {
        useUiStateStore().createSnackbar({
          message: `Izin ${formData.value.type} berhasil ditambahkan.`
        });
        showFormDialog.value = false;
        formData.value = {
          value: '',
          type: '',
          agency: '',
          date: undefined
        };
        emit('refresh:data');
      }

      isLoading.value = false;
    };

    const onDelete = async () => {
      const data: any = { ...formData.value };

      isLoading.value = true;

      const result = await store.deletePermission(data);
      if (result) {
        useUiStateStore().createSnackbar({
          message: `Izin ${formData.value.type} berhasil dihapus.`
        });
        showDeleteDialog.value = false;
        formData.value = {
          value: '',
          type: '',
          agency: '',
          date: undefined
        };
        emit('refresh:data');
      }

      isLoading.value = false;
    };

    const clickDelete = (data: any) => {
      formData.value = { ...data };
      showDeleteDialog.value = true;
    };

    const clickEdit = (data: any) => {
      formData.value = { ...data };
      if (formData.value.date)
        // @ts-ignore
        formData.value.date = new Date(formData.value.date)
          .toISOString()
          .slice(0, 10);
      showFormDialog.value = true;
    };

    return {
      headers,
      isLoading,
      showFormDialog,
      formData,
      reRenderCount,
      onSubmit,
      showDeleteDialog,
      clickDelete,
      onDelete,
      loadPermissionFile,
      clickEdit
    };
  }
});
