












import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref
} from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import useInputRules from '@/@core/helpers/inputRules';
import { toCurrency } from '@/@core/helpers/formatter';

export default defineComponent({
  components: { KBaseInput },
  name: 'KPatternInput',
  props: {
    pattern: {
      type: Function as PropType<(v: string) => any>,
      default: toCurrency
    }
  },
  setup(props, { emit, attrs }) {
    const { label, rules, ...filteredAttrs } = attrs;

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );

    onMounted(() =>
      emit('input', props.pattern(attrs.value as string))
    );

    return {
      useInputStyles,
      filteredAttrs,
      formattedRules
    };
  }
});
