// const basePath = 'storage/'
const defaultProductImage = 'default_product.png';
const defaultIndustryImage = 'default_building.png';
const defaultPermissionImage = 'default_permission.png';
const defaultProfileImage = 'default_profile.jpg';

// const protocol = window.location.protocol + '//'
// const hostname = window.location.hostname
// const port = 4021
// const url = `${hostname}:${port}`
// const fullUrl = `${protocol}${url}`

// const isProduction = hostname.includes("localhost") || hostname.includes("127.0.0.1") || hostname.includes("0.0.0.0")
// const useLocalPath = (path: string) => require('@/../../storage/' + (path || defaultProductImage))
// const useServerPath = (path: string) => `${fullUrl}/${path}`
// const useLoadPath = (path: string) => isProduction ? useServerPath(path) : useLocalPath(path)

// export const loadProductFile = (path: string) => useLoadPath(path || defaultProductImage)
// export const loadIndustryFile = (path: string) => useLoadPath(path || defaultIndustryImage)
// export const loadPermissionFile = (path: string) => useLoadPath(path || defaultPermissionImage)
// export const loadProfileFile = (path: string) => useLoadPath(path || defaultProfileImage)

const protocol = window.location.protocol + '//';
const hostname = window.location.hostname;
const port = 4021;

const isDevelopment =
  hostname.includes('localhost') ||
  hostname.includes('127.0.0.1') ||
  hostname.includes('0.0.0.0');

let url = hostname;
if (isDevelopment) url += `:${port}`;
const baseUrl = `${protocol}${url}`;

const useLocalPath = (path: string) =>
  require('@/../../storage/' + path);
const useServerPath = (path: string) => `${baseUrl}/storage/${path}`;
const useLoadPath = (path: string) =>
  isDevelopment ? useLocalPath(path) : useServerPath(path);

export const loadProductFile = (path: string) =>
  useLoadPath(path || defaultProductImage);
export const loadIndustryFile = (path: string) =>
  useLoadPath(path || defaultIndustryImage);
export const loadPermissionFile = (path: string) =>
  useLoadPath(path || defaultPermissionImage);
export const loadProfileFile = (path: string) =>
  useLoadPath(path || defaultProfileImage);
