















































import { loadPermissionFile } from '@/@core/helpers/loader';
import { defineComponent, ref } from '@vue/composition-api';
import KDialog from '@/@core/components/dialogs/KDialog.vue';

export default defineComponent({
  name: 'KImageViewer',
  components: {
    KDialog
  },
  props: {
    filePath: {
      type: String
    },
    buttonText: {
      type: String,
      default: 'Lihat Gambar'
    },
    buttonIcon: {
      type: String,
      default: 'mdi-file-image-outline'
    },
    downloadText: {
      type: String,
      default: 'Download Gambar'
    },
    cancelText: {
      type: String,
      default: 'Tutup'
    }
  },
  setup() {
    const showDialog = ref(false);

    return {
      loadPermissionFile,
      showDialog
    };
  }
});
