var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-card',{staticClass:"pa-4"},[_c('v-layout',{staticClass:"align-center"},[_c('k-text',{staticClass:"title",attrs:{"text":"Izin Perusahaan"}}),_c('v-spacer'),_c('k-button-action',{staticClass:"mr-n4 mr-sm-0",attrs:{"loading":_vm.isLoading,"color":"error"},on:{"click":function($event){(_vm.formData = {
            value: '',
            type: '',
            agency: '',
            date: undefined
          }),
            (_vm.showFormDialog = true)}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_c('k-text',{staticClass:"text-none font-weight-bold",attrs:{"text":"Tambah"}})],1)],1),_c('div',{staticClass:"mx-n4 my-4"},[_c('v-divider')],1),_c('div',{staticClass:"mx-n4"},[_c('v-data-table',{attrs:{"items":_vm.data.permissions,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.filePath",fn:function(ref){
            var item = ref.item;
return [_c('k-image-viewer',{attrs:{"filePath":item.filePath}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',{staticClass:"align-center"},[_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.clickEdit(item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pencil-outline')}})],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.clickDelete(item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-delete-outline')}})],1)],1)]}}])})],1)],1),_c('k-dialog',{attrs:{"title":"Tambah Izin","useActions":""},on:{"submit":_vm.onSubmit},model:{value:(_vm.showFormDialog),callback:function ($$v) {_vm.showFormDialog=$$v},expression:"showFormDialog"}},[_c('div',{staticClass:"px-6 py-4"},[_c('k-text-input',{attrs:{"label":"Nomor Perizinan"},model:{value:(_vm.formData.value),callback:function ($$v) {_vm.$set(_vm.formData, "value", $$v)},expression:"formData.value"}}),_c('k-text-input',{attrs:{"label":"Jenis Perizinan"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('k-text-input',{key:_vm.reRenderCount,attrs:{"label":"Tanggal","type":"date"},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}}),_c('k-text-input',{attrs:{"label":"Instansi Pemberi Izin"},model:{value:(_vm.formData.agency),callback:function ($$v) {_vm.$set(_vm.formData, "agency", $$v)},expression:"formData.agency"}}),_c('k-file-input',{attrs:{"outlined":"","accept":"image/*","label":"Foto Perizinan"},model:{value:(_vm.formData.file),callback:function ($$v) {_vm.$set(_vm.formData, "file", $$v)},expression:"formData.file"}})],1)]),_c('k-dialog',{attrs:{"title":"Hapus Izin","useActions":"","submitColor":"error","submitText":"Hapus","text":("Hapus izin " + (_vm.formData.type) + " ?")},on:{"submit":_vm.onDelete},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }